/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 14:21:50
 * @FilePath: \J1-M2-QRP-CODE\actions\util.js
 */

import affDomains from '../config/trace/affDomains';

export function getPathName(){
	console.log('sss',global.location && global.location.pathname)
	// var pathname = window && window.location.pathname.split("/");
	// if(pathname[1].includes('m')) return pathname[1];
}


export const Cookie = {
	Create: (name, value, days) => {
		let expires;
		if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toGMTString();
		} else {
		expires = '';
		}
		let domain = document.location.hostname;
		let domainSplits = domain.split('.');
		let isIPDomain = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
		domain
		);
		if (!(domainSplits.length === 1) && !isIPDomain) {
		if (domainSplits.length >= 3) {
			domainSplits = domainSplits.slice(-2);
			domain = '.' + domainSplits.join('.');
		} else {
			domain = '.' + domainSplits.join('.');
		}
		}
		var encodeValue = encodeURIComponent(value);
		document.cookie = name + '=' + encodeValue + expires + '; path=/; domain=' + domain;
		// document.cookie = name + '=' + value + expires + '; path=/;';
	},
	Delete: cookieName => {
		Cookie.Create(cookieName, '', -1);
		document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
	},
	Get: cookieName => {
		var name = cookieName + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
		}
		return '';
	},
	GetCookieKeyValue: cookieName => {
		let aff = Cookie.Get(cookieName);
		return aff ? aff.split('=')[1] : '';
	}
};


/**
 * 
 * @param {String} querystring 截取URL 参数
 */
export const checkAffQueryString = (querystring, IS_ONLY_GET_QUERYSTRING_AFF=false) => {
	  var urlParams = new URLSearchParams(querystring);
  
	  let qsAff = '';
	  if (urlParams.has('aff')) { qsAff = urlParams.get('aff'); }
	  if (urlParams.has('affCode')) { qsAff = urlParams.get('affCode'); }
	  if (urlParams.has('affcode')) { qsAff = urlParams.get('affcode'); }
	  if (IS_ONLY_GET_QUERYSTRING_AFF) { return qsAff; }

	// ?aff=808296&media=jtoq&web=0&referrer=https://bbs.hubaobo.com/forum.php
	if (querystring !== '') {
	  if (qsAff) { Cookie.Create('CO_affiliate', 'affiliate=' + qsAff, 2); }
	  if (urlParams.has('media')) Cookie.Create('Media', 'Media=' + urlParams.get('media'), 2);
	  if (urlParams.has('web')) Cookie.Create('CO_WebStieID', 'WebStieID=' + urlParams.get('web'), 2);
	  if (urlParams.has('referrer'))
		Cookie.Create('Referer', 'Referer=' + urlParams.get('referrer'), 2);
	}else{
		Cookie.Delete('CO_affiliate');
		Cookie.Delete('Media');
		Cookie.Delete('referrer');
	}

	//處理根據域名寫死的aff code
	const aff_hardcode = localStorage.getItem('aff_hardcode');
	if (aff_hardcode) {
		Cookie.Create('CO_affiliate', 'affiliate=' + aff_hardcode, 2);
	}
};

const getAffFromHardCode = () => localStorage.getItem('aff_hardcode'); // from affDomain.js  or  traceConfigs.js
export const getAffFromCookie = () => {
	if (typeof window !== "undefined") {
		return Cookie.GetCookieKeyValue('CO_affiliate') || '';
	}
	return '';
}


export const getBrowserVersion = () =>{

	const isMobile = (thisUserAgent) => {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				thisUserAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				thisUserAgent.substr(0, 4)
			)
		) {
			return true;
		}
		return false;
	};


	const thisUserAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (isMobile(thisUserAgent)) {
		if (/(android)/i.test(thisUserAgent) || /iPad|iPhone|iPod/i.test(thisUserAgent)) return 6;
	} else {
		//Desktop
		if (thisUserAgent.indexOf('Win') !== -1 || thisUserAgent.indexOf('Mac') !== -1) return 7;
	}
}


/**
 * URL 跳转
 * @param {*} url 
 */
export const _handleTheMainSiteUrlOfBackendResponse = (url) => {
	
	let IS_IN_IFRAME =
      global.self !== global.top ||
      RegExp("(#|,)iniframe=1").test(location.href); // our url design: http....#aaa=1,bbb=2,ccc=3
	let MAIN_SITE_URL = url;
	
	if (MAIN_SITE_URL.indexOf("http://") === 0) {
      MAIN_SITE_URL = "https://" + MAIN_SITE_URL.substr(7);
    }

	if (IS_IN_IFRAME) {
		global.parent.postMessage({ mainSiteUrl: MAIN_SITE_URL }, "*");
	} else {
		global.location.href = MAIN_SITE_URL;
	}
}

// This function determines the final affiliate value
export const getAffValue = () => {
	let affiliateValue = '';
  
	// Check if window and document are available (browser environment check)
	if (typeof window === 'undefined' || typeof document === 'undefined') {
	  return null; // Return null if not in a browser environment
	}

	// 1. Check for domain name and hardcoded affiliate value
	const hardCodeAff = getAffFromHardCode();
	if (hardCodeAff) { return hardCodeAff; }
  
	// 2. Check the query string for affiliate value
	const queryString = window.location.search;
	const qsAff = checkAffQueryString(queryString, true);
	return qsAff;
  }


  export const addAffiliateParamToUrl = (url) => {
	// Get the affiliate value from getAffValue function
	const affValue = getAffValue();
  
	// If there's no affiliate value to set, return the original URL
	if (!affValue) {
	  return url;
	}
  
	try {
	  let urlObj = new URL(url, window.location.origin); // Use base URL for relative paths
  
	  // Check if the URL already has an 'aff' parameter
	  if (urlObj.searchParams.has('aff')) {
		const existingAffValue = urlObj.searchParams.get('aff');
  
		// Compare the existing 'aff' value with the new 'aff' value
		if (existingAffValue === affValue) {
		  // If they are the same, return the original URL
		  return url;
		} else {
		  // If they are different, update the 'aff' parameter with the new value
		  urlObj.searchParams.set('aff', affValue);
		  return urlObj.toString();
		}
	  } else {
		// If there's no existing 'aff' parameter, add the new 'aff' value
		urlObj.searchParams.set('aff', affValue);
		return urlObj.toString();
	  }
	} catch (error) {
	  console.error("Invalid URL:", url);
	  return url; // Return the original URL in case of an error
	}
  };

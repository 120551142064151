/*
 * @Date: 2023-03-22 10:20:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 14:32:18
 * @FilePath: \J1-M2-QRP-CODE\components\hayhar\header.js
 */
import React from "react";
import { Input , Button } from 'antd';
import { _handleTheMainSiteUrlOfBackendResponse, addAffiliateParamToUrl } from "../../actions/util";
import { fetchRequest , Apiversion } from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";
import Toast from '@/Toast';

class Header extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			password : '',
			username : '',
		};

		this.loginMobile = this.loginMobile.bind(this);
		this.login = this.login.bind(this);
	}

	loginMobile(){
		let _that = this;
		let { mainSiteUrl } = this.props;
		console.log(mainSiteUrl);
		_handleTheMainSiteUrlOfBackendResponse(`${mainSiteUrl}`);
	}


	join(){
		
		global.globalGtag(`Style1_QRP`,'Registration form','Register_topnav','event')
		const dom = document.getElementById('mobile-heading');
		dom.scrollIntoView({behavior : 'smooth'});
	}

	/**
	 * 登录
	 * @returns 
	 */
	login(){
		let { password , username } = this.state;
		debugger
		if(!password && !username) return ;
		let params = {
			currentDomain : global.location.origin || '',
			hidE2 :(global.E2GetBlackbox && global.E2GetBlackbox().blackbox )|| '',
			language : 'zh-cn',
			password : password , 
			username : username
		}
		Toast.loading()
		try {
			fetchRequest(ApiPort.Login , 'POST' , params , Apiversion).then(res =>{
				try {
					Toast.hide();
					if(res){
						if(res.isSuccess){
							_handleTheMainSiteUrlOfBackendResponse(`${res.url}&token=${res.token}`);
						}else{
							this.props.openModal(res.message);
							// Toast.fail(res.message);
						}
					}
				} catch (error) {
					console.log('====================================');
					console.log('服务器错误');
					console.log('====================================');
				}
			})
		} catch (error) {
			console.log('====================================');
			console.log('服务器错误');
			console.log('====================================');
		}
		
	}
	
	render(){
		let { mainSiteUrl , pathname } = this.props;

		return (
			<>
				<div className={'top web'}>
					<a href={addAffiliateParamToUrl(mainSiteUrl)}>
						<img src="/static/images/hayhar/fun88-logo.png" className={'qrp-header-logo'} />
					</a>
					{
						pathname && <div className="liveChat" onClick={()=>{this.props.openLiveChat()}}>
							<img src="/static/images/liveChat/icon-cs.png" />
							<span>แชทสด</span>
						</div>
					}
					
				</div>
				
				{/* mobile and ipad */}
				<div className="top mobile">
					<a  href={addAffiliateParamToUrl(mainSiteUrl)}>
						<img src="/static/images/hayhar/fun88-logo.png" className={'qrp-header-logo'} />
					</a>
					{pathname &&<div className="liveChat" onClick={()=>{this.props.openLiveChat()}}>
						<img src="/static/images/liveChat/icon-cs.png" />
					</div>}
				</div>
			</>
		)
	}
}


export default Header;
